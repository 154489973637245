import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { APP_IMG, APP_URL } from '.';
import { BsTwitterX } from 'react-icons/bs';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
export const WEB_NAME = 'thepeople';
export const WEB_TITLE = 'ThePeople - Everyone Has Their Own Story';
export const WEB_DESC = 'Everyone Has Their Own Story';
export const WEB_KEYWORD = 'interview, culture, politics, business, environment, social, history, news';
export const WEB_LOGO = 'logo.png';
export const THEME_WEB = '#000000';
export const LOGO_192 = 'Icon-192.png';
export const LOGO_FAVICON = 'favicon.ico';
export const LOGO_APPLE = 'logo-apple-touch.png';
export const IMAGE_DEFAULT = `${APP_IMG}/default.jpg`; // default image

export const TWITTER = {
  HANDLE: 'The People',
  CREATOR: '@thepeople_co',
  SITE: '@thepeople_co'
};

export const FACBOOK = {
  PAGE: '1023078414531349',
  APP_ID: '1023078414531349'
};

/* -------------------------------------------------------------------------- */
/*                                   FOOTER                                   */
/* -------------------------------------------------------------------------- */
export const WEB_URL = {
  WEB: `${APP_URL}/`,
  FACEBOOK: 'https://www.facebook.com/thepeoplecoofficial/',
  TWITTER: 'https://twitter.com/thepeople_co',
  INSTAGRAM: 'https://www.instagram.com/thepeoplecoofficial/',
  YOUTUBE: 'https://www.youtube.com/c/THEPEOPLECoOfficial'
};

export const ADDRESS_ORG = {
  CONTENT: 'ชั้น 7 เลขที่ 1854 ถ.เทพรัตน แขวงบางนาใต้ เขตบางนา',
  PROVINCE: 'กรุงเทพฯ',
  REGION: 'TH',
  POSTCODE: '10260',
  COUNTRY: 'THAILAND'
};

export const SOCIAL_LIST: any = [
  { id: 1, icon: <FaFacebookF id='facebook' />, link: WEB_URL.FACEBOOK, name: 'facebook', className: 'icon-facebook' },
  { id: 2, icon: <BsTwitterX id='X' />, link: WEB_URL.TWITTER, name: 'X', className: 'icon-X' },
  { id: 3, icon: <FaInstagram id='instagram' />, link: WEB_URL.INSTAGRAM, name: 'instagram', className: 'icon-instagram' },
  { id: 4, icon: <FaYoutube id='youtube' />, link: WEB_URL.YOUTUBE, name: 'youtube', className: 'icon-youtube' }
];
