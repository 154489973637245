export const getScrollElement = (el: any) => {
  let newEl = el;
  let elTop = newEl.offsetTop - newEl.offsetHeight * 3;
  while (newEl.offsetParent) {
    newEl = newEl.offsetParent;
    elTop += newEl.offsetTop;
  }
  return elTop;
};

export const isSectionOne = () => {
  const sectionEl = document.getElementById('section-1');
  if (sectionEl) {
    return true;
  }
  return false;
};

export const isShouldFetch = () => {
  const footerEl = document.querySelector('.footer-wrapper');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop > scrollElement;
  }
  return false;
};

export const isShouldFetchByline = () => {
  // Get the scroll position of the current page
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  // Get the height of the entire document and the height of the viewport
  const docHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;
  
  // Calculate how far the user has scrolled (as a percentage)
  const scrollPercent = (scrollTop / (docHeight - windowHeight)) * 100;
  if (scrollPercent >= 80) {
    return true;
}
  return false;
};

export const isShouldFetchReadmore = () => {
  const footerEl = document.querySelector('.fetch-remore');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop + 300 > scrollElement;
  }
  return false;
};
